@use '@angular/material' as mat;

// show label for more dense inputs as well
.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
    .mat-mdc-floating-label {
    display: inline !important;
}

@include mat.core();

$ai4eosc-palette-primary: (
    50: #ffebf0,
    100: #ffced9,
    200: #ffadbf,
    300: #ff8ca5,
    400: #ff7492,
    500: #ff5b7f,
    600: #ff5377,
    700: #ff496c,
    800: #ff4062,
    900: #ff2f4f,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffdae0,
    A700: #ffc1ca,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

/* For use in src/lib/core/theming/_palette.scss */
$ai4eosc-palette-secondary: (
    50: #e0f1f2,
    100: #b3dbde,
    200: #80c3c9,
    300: #4dabb3,
    400: #2699a2,
    500: #008792,
    600: #007f8a,
    700: #00747f,
    800: #006a75,
    900: #005763,
    A100: #93efff,
    A200: #60e8ff,
    A400: #2de1ff,
    A700: #14ddff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$ai4eosc-app-primary: mat.m2-define-palette($ai4eosc-palette-primary);
$ai4eosc-app-accent: mat.m2-define-palette($ai4eosc-palette-secondary);
$ai4eosc-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

$ai4eosc-app-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $ai4eosc-app-primary,
            accent: $ai4eosc-app-accent,
            warning: $ai4eosc-app-warn,
        ),
        typography:
            mat.m2-define-typography-config(
                $font-family: "'Raleway', Arial, Helvetica, sans-serif",
            ),
        density: 0,
    )
);

// Emit theme-dependent styles for common features used across multiple components.
@include mat.all-component-themes($ai4eosc-app-theme);

.cdk-overlay-pane {
    max-width: 100vw !important;
    justify-content: center;

    .mat-dialog-container {
        border-radius: 0;
        width: 100vw;
        height: 100vh;

        @media (min-width: breakpoint('md')) {
            width: 576px;
            height: auto;
            border-radius: 16px;
        }

        @media (min-width: breakpoint('lg')) {
            width: 760px;
        }

        .mat-dialog-content {
            max-height: 75vh;
            padding-bottom: 24px;
            border-bottom: 1px solid var(--white-two);

            @media (min-width: breakpoint('md')) {
                padding-bottom: 8px;
                border-bottom: none;
            }
        }
    }
}

.mat-form-field-appearance-outline {
    &.mat-focused {
        .mat-form-field-outline-thick {
            color: var(--greyish-brown);
        }
    }

    &.mat-form-field-invalid {
        &.mat-form-field-invalid {
            .mat-form-field-outline-thick {
                color: var(--neon-red);
            }
        }
    }
}

.mat-form-field-flex {
    align-items: center !important;
}

.mat-form-field {
    .mat-form-field-label {
        color: var(--black-60);
    }

    &.mat-focused {
        .mat-form-field-label {
            color: var(--greyish-brown);
        }
    }

    &.mat-form-field-invalid {
        .mat-form-field-label {
            color: var(--neon-red);
        }
    }

    &.mat-form-field-flex {
        align-items: center;
    }

    &.mat-form-field-appearance-outline {
        .mat-form-field-suffix {
            color: var(--neon-red);

            .mat-icon-button:not([disabled]) {
                color: var(--neon-red);
            }
        }
    }
}

.mat-input-element {
    caret-color: var(--greyish-brown);
}

.mat-form-field-invalid {
    .mat-input-element {
        caret-color: var(--neon-red);
    }
}

.mat-error {
    color: var(--neon-red);
}

.mat-dialog-title {
    margin: 0 0 30px;
    font-size: 24px;
    font-weight: var(--raleway-light);
    color: var(--warm-grey);
}

.mat-dialog-actions {
    justify-content: flex-end;

    .mat-button {
        color: var(--neon-red);

        &.primary {
            font-weight: var(--raleway-bold);
        }

        .mat-riple {
            background-color: var(--neon-red);
        }
    }
}

.mat-checkbox-frame {
    border-color: var(--neon-red);
}

.mat-checkbox-indeterminate,
.mat-checkbox-checked {
    &.mat-accent {
        .mat-checkbox-background {
            background-color: var(--neon-red);
        }
    }
}

.mat-checkbox-frame {
    border-color: var(--neon-red);
}

.mat-checkbox-label {
    color: var(--greyish-brown);
}

button.mat-menu-item {
    color: var(--warm-grey);
    font-size: var(--font-l);
    font-weight: var(--raleway-medium);
    outline: none;
}
